jQuery(function($) {
    'use strict';

    // Add Menu children angle
    $('#mainMenu > ul li a').each(function () {
        if ($(this).parent('li').children('ul').length > 0) {
            $(this).append('<span class="parent-angle"><i class="icon-arrow-down"></i></span>');
        }
    });


    // mobile menu activation
    $('#mainMenu').meanmenu({
        meanMenuContainer: '.header-menu',
        meanScreenWidth: '1199',
    });

    // scroll to top
    const btnToTop = $('.btn-to-top');
    $(window).scroll(function() {
        if ($(window).scrollTop() > 300) {
            btnToTop.addClass('show');
        } else {
            btnToTop.removeClass('show');
        }
    });

    btnToTop.on('click', function(event) {
        event.preventDefault();
        $('html, body').animate({scrollTop:0}, 300);
    });


    $('#js-search-main').on('keyup', function () {
        const input = $(this);
        const url = input.data('url');
        const output = $('#js-search-main-scope');
        if (input.val().length > 3) {
            $.post(url,
                {search: input.val()},
                function (response) {
                    output.html('');
                    output.append(response);
                });

        } else {
            output.html('');
        }
    });
    $('#js-search-main-scope ul li a').on('click', function (e) {
        e.preventDefault();
        $('#js-search-main').val(this.text);
        $('#js-search-main-scope').html('');
    });


    // reset prevent default in desktop and mobile menu
    $('#mainMenu a.dropdown-toggle, nav.mean-nav a.dropdown-toggle').on('click', function(e){
        const menuUrl = $(this).attr('href');

        if (menuUrl !== undefined) {
            window.location = menuUrl;
            return;
        }
    });

    // accordion
    $('.accordeon .panel-heading').on('click', function (e) {
        e.preventDefault();
        let $link = $(e.currentTarget);
        $('.panel-heading').removeClass('active');
        $('.panel-collapse').slideUp();
        let $accordion = $link.next('.panel-collapse');
        if(!$accordion.is(':visible')) {
            $accordion.slideToggle();
            $link.toggleClass('active');
        }
    });

    // modal
    $('#btn-feedback').on('click', function(e){
        e.preventDefault();
        let target = $(this).data('modal');
        $(target).slideDown();
    });


    $('.modal .overlay__close').on('click', function(){
        $(this).closest('.modal').slideUp();

        /*setTimeout(function() {
            $('.modal-content .modal-title').text('Получить бесплатную консультацию»');
        }, 500);*/
    });

    $('.btn-closex').on('click', function(){
        $(this).closest('.modal').slideUp();
    });

    $('.modal').on('click', function(e){
        let content = $('.modal-content');
        if(!content.is(e.target) && !content.has(e.target).length) {
            $(this).slideUp();
        }
    });

    // tabs
    $('.nav-tabs .tab-link').on('click', function(e){
        e.preventDefault();
        let target = $(this).data('tab');
        $('.nav-tabs .list-item').removeClass('active');
        $(this).parent('.list-item').addClass('active');
        $('.tab-pane').removeClass('active');
        $(target).addClass('active');
    });

    // close modal
    $('.alert-message-container').on('click', function() {
        $(this).remove();
    })

    const title = $('.item-article h1[itemprop="headline"]').text();

    $('.item-article img').each(function (counter) {
        const counterText = counter === 0 ? '' : ` ${counter}`;
        $(this).attr('alt', `${title} фото${counterText}`);
    });

    $('.banner-offer .banner-btn').on('click', function(){
        $('.modal-content .modal-title').text('Узнать подробнее');
    })

    $('a.link-table-of-contents').click(function () {
		var target = $(this).attr('href');
		target = document.getElementById(target.substring(1));
		$('html, body').animate({ scrollTop: $(target).offset().top - 135 }, 600);
	});

	var anchor = location.hash;
	var anchorEl = document.getElementById(anchor.substring(1));
	if(anchorEl) {
		var target = $(anchorEl);
		$('html, body').animate({ scrollTop: $(target).offset().top - 135 }, 600);
	}
});
